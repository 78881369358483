<template>
  <v-card>
    <v-card-title class="pt-1 pl-2 pb-0 primary--text text-h8">
      {{ title }}
    </v-card-title>
    <v-container>
      <v-form v-model="isFormValid" @submit.prevent="addDetalleAlicuotaIva">
        <v-row>
          <v-col cols="12" md="5" class="pt-0 pl-1 pr-1 pb-0">
            <v-text-field
              v-model="conceptoDetalleAlicuotaIva"
              hide-details="auto"
              dense
              id="conceptoNombre"
              ref="conceptoNombre"
              outlined
              :rules="rules.required.concat(rules.maxLength(conceptoDetalleAlicuotaIva,50))"
              label="Concepto"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="5" class="pt-0 pl-1 pr-1 pb-0">
            <v-autocomplete
              v-model="alicuotaIvaDetalleAlicuotaIva"
              :items="alicuotasIva"
              outlined
              :rules="rules.required"
              clearable
              dense
              item-text="value"
              @input="setIvaAlicuota(alicuotaIvaDetalleAlicuotaIva)"
              item-value="id"
              label="Alícuota IVA"
              return-object
              hide-details="auto"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" class="pt-0 pl-1 pr-1 pb-0">
            <v-text-field
              v-model="porcIvaDetalleAlicuotaIva"
              hide-details="auto"
              dense
              outlined
              readonly
              disabled
              label="% IVA"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="pt-1 pb-0 pr-1 pl-1">
            <currency-input
              v-model="netoDetalleAlicuotaIva"
              label="Neto"
              :options="currencyOptions"
              :filled="bloqueaIvaNeto"
              @change="updateIvayTotalDetalleAlicuotaIva()"
              :disabled="bloqueaIvaNeto"
              :rules="bloqueaIvaNeto ? [] : rules.required"
              :hideDetails="'auto'"
            ></currency-input>
          </v-col>
          <v-col cols="12" md="4" class="pt-1 pb-0 pr-1 pl-1">
            <currency-input
              v-model="ivaDetalleAlicuotaIva"
              label="IVA"
              :disabled="bloqueaIvaNeto"
              :filled="bloqueaIvaNeto"
              :options="currencyOptions"
              :rules="[]"
              :hideDetails="'auto'"
            ></currency-input>
          </v-col>
          <v-col cols="12" md="4" class="pt-1 pb-0 pr-1 pl-1">
            <currency-input
              v-model="totalDetalleAlicuotaIva"
              label="Total"
              :options="currencyOptions"
              :rules="rules.required"
              :readonly="!bloqueaIvaNeto"
              :disabled="!bloqueaIvaNeto"
              :hideDetails="'auto'"
            ></currency-input>
          </v-col>
        </v-row>
        <v-card-actions class="pb-0 pt-1 pr-0 ">
          <v-col cols="12" class="text-right p-0">
            <v-btn
              small
              outlined
              @click="toggleNuevoDetalleAlicuotasIva"
              color="error"
              class="mr-2"
              >Cerrar</v-btn
            >
            <v-btn small type="submit" :disabled="!isFormValid" color="primary"
              >Guardar</v-btn
            ></v-col
          >
        </v-card-actions>
        <v-row> </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import { set } from '@vue/composition-api';

export default {
  name: "EditDetalleAlicuotasIva",
  props: {
    detalleAlicuotaToEdit: { type: Object, require: false, default: null },
    itemsCount: { type: Number, require: false, default: 0 },
    fechaComp: { type: String, require: false, default: "" },
    letraComp: { type: String, require: false, default: "" }
  },
  components: {
    CurrencyInput
  },
  data: () => ({
    conceptoDetalleAlicuotaIva: null,
    title: "Nuevo detalle alícuotas IVA",
    rules: rules,
    alicuotaIvaDetalleAlicuotaIva: null,
    porcIvaDetalleAlicuotaIva: null,
    netoDetalleAlicuotaIva: null,
    alicuotasIva: [],
    ivaDetalleAlicuotaIva: null,
    totalDetalleAlicuotaIva: null,
    isFormValid: false,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    }
  }),
  async created() {
   
    await this.getAlicuotasIva();
    if (this.detalleAlicuotaToEdit != null) {
      this.setDetalleAlicuotaToEdit();
    }
    this.$refs.conceptoNombre.focus()
  },
 computed:{
  bloqueaIvaNeto(){
    
    return this.letraComp == "C" || this.letraComp == "B" || this.porcIvaDetalleAlicuotaIva == 0
  }
 },

  watch: {
    totalDetalleAlicuotaIva(value) {
      if (this.porcIvaDetalleAlicuotaIva > 0) {
        let porcentaje = this.porcIvaDetalleAlicuotaIva / 100;
        this.netoDetalleAlicuotaIva =
          (value / (this.porcIvaDetalleAlicuotaIva + 100)) * 100;
        this.ivaDetalleAlicuotaIva = this.netoDetalleAlicuotaIva * porcentaje;
      } else {
        this.netoDetalleAlicuotaIva = value;
      }
    },
    ivaDetalleAlicuotaIva(newValue) {
      this.updateTotalDetalleAlicuotaIva();
    }
  },
  methods: {
    ...mapActions({
      getIvaPorcentajes: "proveedores/getIvaPorcentajes",
      getIvaPorcValorXId: "proveedores/getIvaPorcValorXId"
    }),
    updateIvayTotalDetalleAlicuotaIva() {
      if (!this.bloqueaIvaNeto) {
        if (
          this.alicuotaIvaDetalleAlicuotaIva != null &&
          this.netoDetalleAlicuotaIva
        ) {
          this.ivaDetalleAlicuotaIva =
            this.porcIvaDetalleAlicuotaIva > 0
              ? (this.netoDetalleAlicuotaIva * this.porcIvaDetalleAlicuotaIva) /
                100
              : 0;
          this.updateTotalDetalleAlicuotaIva();
        }
      }
    },

    updateTotalDetalleAlicuotaIva() {
      if (!this.bloqueaIvaNeto) {
        if (
          this.netoDetalleAlicuotaIva != null &&
          this.ivaDetalleAlicuotaIva != null
        ) {
          this.totalDetalleAlicuotaIva =
            parseFloat(this.netoDetalleAlicuotaIva) +
            parseFloat(this.ivaDetalleAlicuotaIva);
        } else {
          this.totalDetalleAlicuotaIva = null;
        }
      }
    },
    async getAlicuotasIva() {
      this.alicuotasIva = await this.getIvaPorcentajes({
        letra: this.letraComp,
        fechaComp: this.fechaComp
      });
    },
    async setIvaAlicuota(alicuotaIvaDetalleAlicuotaIva) {
      this.netoDetalleAlicuotaIva = null;
      this.ivaDetalleAlicuotaIva = null;
      this.totalDetalleAlicuotaIva = null;

      if (this.alicuotaIvaDetalleAlicuotaIva != null) {
        this.porcIvaDetalleAlicuotaIva = await this.getIvaPorcValorXId({
          ivaPorcId: this.alicuotaIvaDetalleAlicuotaIva.id,
          fechaComp: this.fechaComp
        });
        // this.porcIvaDetalleAlicuotaIva = this.alicuotaIvaDetalleAlicuotaIva.iva;
      }
    },

    setDetalleAlicuotaToEdit() {
      this.title = "Editar detalle alícuotas IVA";
      this.conceptoDetalleAlicuotaIva = this.detalleAlicuotaToEdit.concepto;
      let alicuotaSelected = this.alicuotasIva.filter(
        alicuota => alicuota.value === this.detalleAlicuotaToEdit.alicuotaIVA
      );
      this.alicuotaIvaDetalleAlicuotaIva = alicuotaSelected[0];
      this.porcIvaDetalleAlicuotaIva = this.detalleAlicuotaToEdit.ivaPorcentaje;
      this.netoDetalleAlicuotaIva = this.detalleAlicuotaToEdit.neto;
      this.ivaDetalleAlicuotaIva = this.detalleAlicuotaToEdit.iva;
      this.totalDetalleAlicuotaIva = this.detalleAlicuotaToEdit.total;
    },
    addDetalleAlicuotaIva() {
      const item = {
        internId:
          this.detalleAlicuotaToEdit != null
            ? this.detalleAlicuotaToEdit.internId
            : this.itemsCount + 1,
        concepto: this.conceptoDetalleAlicuotaIva,
        alicuotaIVA:
          this.alicuotaIvaDetalleAlicuotaIva != null
            ? this.alicuotaIvaDetalleAlicuotaIva.value
            : null,
        ivaPorcentaje: this.porcIvaDetalleAlicuotaIva,
        neto: this.letraComp == "C"
          ? this.totalDetalleAlicuotaIva
          : this.netoDetalleAlicuotaIva,
        iva: this.ivaDetalleAlicuotaIva,
        total: this.totalDetalleAlicuotaIva
      };
      if (this.detalleAlicuotaToEdit != null) {
        this.$emit("editDetalleAlicuotaIva", item);
        this.$emit("toggleNuevoDetalleAlicuotasIva");
      } else {
        this.$emit("addDetalleAlicuotaIva", item);
        this.$emit("toggleNuevoDetalleAlicuotasIva");
      }
    },
    toggleNuevoDetalleAlicuotasIva() {
      this.$emit("toggleNuevoDetalleAlicuotasIva");
    }
  }
};
</script>

<style></style>
