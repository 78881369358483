<template>
  <v-container class="p-0">
    <v-card class="pr-4">
      <v-card-title class="pb-0 pt-1">
        <span class="primary--text">{{ title }}</span>
      </v-card-title>
      <v-form
        v-model="isFormValid"
        ref="select-cuenta"
        id="select-cuenta"
        @submit.prevent="selectCuenta()"
      >
        <v-row @keypress.enter="filterCuenta" class="px-5 pb-0">
          <v-col cols="6" sm="12" md="3" class="py-0 px-2">
            <v-text-field
              v-model="cuentaSearchCodigo"
              label="Código"
              dense
              clearable
              v-mask="mascara"
              :rules="[
                rules.requiredTrim(cuentaSearchCodigo),
                rules.maxLength(cuentaSearchCodigo, 50)
              ]"
              outlined
            >
            </v-text-field>
          </v-col>

          <v-col cols="6" sm="12" md="4" class="py-0 px-2">
            <v-text-field
              v-model="cuentaSearchNombre"
              label="Nombre/s"
              dense
              :rules="[
                rules.requiredTrim(cuentaSearchNombre),
                rules.maxLength(cuentaSearchNombre, 50)
              ]"
              clearable
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col class="py-0 pl-0 pr-3">
            <v-btn
              color="primary"
              class="to-right"
              :disabled="!isFormValid"
              @click="filterCuenta"
            >
              Buscar
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              class="elevation-1 pt-0"
              :headers="headers"
              :items="cuentasResult"
              :loading="loading"
              dense
              item-key="ctaId"
              :search="search"
              fixed-header
              show-select
              :items-per-page="500"
              :height="dataTableHeight"
              hide-default-footer
              single-select
              v-model="cuentaSelected"
              @input="selectCuenta()"
            >
              <template v-slot:[`item.esEgreso`]="{ item }">
                <v-icon small color="primary">
                  {{ item.esEgreso ? checkIcon : "" }}
                </v-icon>
              </template>
              <template v-slot:top>
                <v-toolbar dense class="py-0">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal()">
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            ref="select-cuenta"
            :disabled="cuentaSelected.length == 0"
          >
            Seleccionar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";

export default {
  name: "BuscarCuentaContable",
  props: {
    planCtaId: { type: Number, require: false, default: null },
    mascara: { type: String, require: false, default: null }
  },
  directives: { mask },
  data() {
    return {
      title: "Búsqueda de cuentas",
      rules: rules,
      checkIcon: enums.icons.CHECK_OUTLINE,
      closeIcon: enums.icons.CLOSE,
      isFormValid: false,
      filtersApplyed: [],
      cuentaSelected: [],
      cuentasResult: [],
      dataTableHeight: null,
      searchIcon: enums.icons.SEARCH,
      search: "",
      ctaId: null,
      cuentaSearchNombre: null,
      cuentaSearchCodigo: null,
      filterApplied: false,
      banderaCuentaSelected: false,
      loading: false,
      headers: [
        {
          text: "Código",
          sortable: false,
          value: "ctaCodigo"
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "ctaNombre"
        },
        {
          text: "Egreso",
          align: "center",
          sortable: false,
          value: "esEgreso"
        }
      ]
    };
  },
  watch: {
    cuentasResult(val) {
      if (val.length >= 4) {
        this.dataTableHeight = "10rem";
      } else if (val.length >= 2) {
        this.dataTableHeight = "7rem";
      } else {
        this.dataTableHeight = "5rem";
      }
    }
  },
  methods: {
    ...mapActions({
      GetCuentaContableBuscarxCodigoyNombre:
        "proveedores/GetCuentaContableBuscarxCodigoyNombre",

      setAlert: "user/setAlert"
    }),
    selectCuenta() {
      this.$emit("toggleModalBusquedaCuenta");
      this.$emit("findCuenta", this.cuentaSelected[0]);
    },
    closeModal() {
      this.$emit("toggleModalBusquedaCuenta");
    },
    // metodos grupos familiares filter
    async filterCuenta() {
      if (this.cuentaSearchNombre != null || this.cuentaSearchCodigo != null) {
        this.loading = true;
        this.customizeFiltersApplied();
        const data = {
          planCtaId: this.planCtaId,
          codigo: this.cuentaSearchCodigo,
          nombre: this.cuentaSearchNombre
        };
        try {
          this.filterApplied = true;
          const response = await this.GetCuentaContableBuscarxCodigoyNombre(
            data
          );
          this.cuentasResult = response;
          this.banderaCuentaSelected
            ? (this.cuentaSelected = this.cuentasResult)
            : [];
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda"
        });
      }
    },
    customizeFiltersApplied() {
      this.filtersApplyed = [];
      if (this.cuentaSelected.length > 0) {
        this.filtersApplyed.splice(0, 1, {
          key: "provNom",
          label: "Nombre",
          model: this.cuentaSelected[0].provNom.toUpperCase()
        });
      } else {
        if (this.cuentaSearchCodigo) {
          this.filtersApplyed.splice(1, 1, {
            key: "codigo",
            label: "Código",
            model: this.cuentaSearchCodigo
          });
        }

        if (this.cuentaSearchNombre) {
          this.filtersApplyed.splice(2, 1, {
            key: "nombre",
            label: "Nombre",
            model: this.cuentaSearchNombre.toUpperCase()
          });
        }
      }
    },
    selectedCuenta() {
      if (this.cuentaSelected.length > 0) {
        this.ctaId = this.cuentaSelected[0].id;
        this.banderaCuentaSelected = false;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
