<template>
  <v-card class="pl-2 pr-4">
    <v-container class="pt-1">
      <!-- IMPUTACION CONTABLE -->
      <v-row>
        <v-col cols="12" class="py-0">
          <v-data-table
            :headers="imputacionContableHeaders"
            :items="imputacionContableItems"
            height="100%"
            fixed-header
            class="elevation-0"
            hide-default-footer
            dense
            item-key="internId"
          >
            <template v-slot:footer>
              <v-row>
                <v-col cols="12" md="6" class="py-0 pl-0 pr-6 text-right">
                  <span class="font-weight-medium text-right">
                    Total imputable:
                    {{ helpers.floatToMoneyString(totalImputable) }}</span
                  >
                </v-col>
                <v-col cols="12" md="6" class="py-0 pl-0 pr-6 text-right">
                  <span class="font-weight-medium text-right">
                    Total:
                    {{
                      helpers.floatToMoneyString(totalImputacionContable)
                    }}</span
                  >
                </v-col>
              </v-row>
            </template>
            <template v-slot:top>
              <!-- FORM IMPUTACION CONTABLE -->
              <v-form
                ref="imputacionContableForm"
                form="imputacionContableForm"
                v-model="isFormImputCbleValid"
                @submit.prevent="saveImputCble()"
              >
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="primary--text py-0 pl-2 font-weight-medium"
                      >Imputación contable</span
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-1 pb-1 pr-1 pl-3"
                    v-if="true"
                  >
                    <v-autocomplete
                      v-model="tipoImputacionProveedorSelected"
                      :items="tiposImputacionProveedor"
                      outlined
                      item-value="compTiId"
                      item-text="compTiNom"
                      clearable
                      return-object
                      @input="setTipoImputacionProveedor()"
                      :hide-details="true"
                      dense
                      label="Tipos de imputación relacionadas al proveedor"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-1 pb-0 pr-1 pl-3">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      clearable
                      ref="codigoCuenta"
                      label="Código cuenta"
                      v-mask="mascaraImputContable"
                      v-model.trim="codigoCuenta"
                      :rules="rules.required"
                      @change="buscarCuenta"
                      autocomplete="off"
                      hide-details=""
                    >
                      <template v-slot:append-outer>
                        <v-tooltip top max-width="20%">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="primary"
                              @click="toggleModalBusquedaCuenta"
                            >
                              {{ searchIcon }}
                            </v-icon>
                          </template>
                          <span>Buscar cuenta por nombre o código</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pt-1 pb-0 pr-1 pl-1 ">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      filled
                      disabled
                      :rules="rules.required"
                      @change="setMontoCuentaPorDefecto()"
                      readonly
                      label="Cuenta"
                      hide-details=""
                      :loading="cuentaLoading"
                      v-model.trim="cuentaSelectedNom"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- BOTON BUSCAR CUENTA -->
                  <!-- <v-col c cols="12" md="1" class="pt-2 pl-2">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          class=""
                          v-bind="attrs"
                          absolute
                          fab
                          x-small
                          color="primary"
                          @click="toggleModalBusquedaCuenta"
                        >
                          <v-icon>{{ searchIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Buscar cuenta por nombre o código</span>
                    </v-tooltip>
                  </v-col> -->
                  <v-col cols="12" md="2" class="pt-1 pb-0 pr-1 pl-0">
                    <currency-input
                      v-model="montoCuenta"
                      label="Importe"
                      ref="montoCuentaContable"
                      :options="currencyOptions"
                      :rules="[rules.decimalGreaterThanZero(montoCuenta)]"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>

                  <v-col cols="12" md="3" class="text-right pl-0 pt-2 pr-5">
                    <v-btn
                      small
                      :disabled="!isFormImputCbleValid || cuentaLoading"
                      shaped
                      color="primary"
                      type="submit"
                    >
                      {{ internIdImputCble != null ? "Guardar" : "Agregar" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </template>
            <template v-slot:[`item.importe`]="{ item }">
              <span>{{ helpers.floatToMoneyString(item.importe) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="editImputCble(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteImputCble(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- CENTROS DE COSTOS -->
      <v-row>
        <v-col cols="12" class="py-0">
          <v-data-table
            :headers="centroCostosHeaders"
            :items="centroCostosItems"
            fixed-header
            class="elevation-0"
            hide-default-footer
            dense
            item-key="internId"
          >
            <template v-slot:footer>
              <v-row>
                <v-col cols="12" md="6" class="py-0 pl-0 pr-6 text-right">
                  <span class="font-weight-medium text-right">
                    Total gastos a imputar:
                    {{ helpers.floatToMoneyString(totalGastoAImputar) }}</span
                  >
                </v-col>
                <v-col cols="12" md="6" class="py-0 pl-0 pr-6 text-right">
                  <span class="font-weight-medium text-right">
                    Total:
                    {{ helpers.floatToMoneyString(totalCentrosDeCostos) }}</span
                  >
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.importe`]="{ item }">
              <span>{{ helpers.floatToMoneyString(item.importe) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="setEditCentroCosto(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteCentroCosto(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
            <!-- FORM CENTRO DE COSTO -->
            <template v-slot:top>
              <v-row>
                <v-col cols="12" md="12" class="pb-1 pt-2">
                  <span class="primary--text py-0 pl-2 font-weight-medium"
                    >Centros de costos</span
                  ></v-col
                >
                <v-col
                  cols="12"
                  md="12"
                  class="pt-1 pb-0 pl-3"
                  v-if="tiposImputacionCentroCosto.length > 0"
                >
                  <v-autocomplete
                    v-model="tiposImputacionCentroCostoSelected"
                    :items="tiposImputacionCentroCosto"
                    outlined
                    item-value="id"
                    item-text="value"
                    clearable
                    :hide-details="true"
                    dense
                    @input="setImputacionContable"
                    label="Tipos de imputación"
                  >
                    <!-- distribucion de centro de costos? -->
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-form
                    ref="centrosCostosForm"
                    form="centrosCostosForm"
                    v-model="isFormCentrosCostosValid"
                    @submit.prevent="saveCentroCosto"
                  >
                    <v-row>
                      <v-col cols="12" md="4" class="pt-1 pb-0 pr-1 pl-3">
                        <v-autocomplete
                          v-model="centroCostoSelected"
                          :items="centroCostos"
                          outlined
                          item-value="id"
                          ref="centroCosto"
                          item-text="value1"
                          clearable
                          @input="setMontoCentroCostoPorDefecto"
                          :rules="rules.required"
                          hide-details=""
                          return-object
                          dense
                          label="Centro de costo"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" class="pt-1 pb-0 pr-1 pl-1">
                        <currency-input
                          v-model="montoCentroCosto"
                          label="Importe"
                          :options="currencyOptions"
                          :rules="[
                            rules.decimalGreaterThanZero(montoCentroCosto)
                          ]"
                          :hideDetails="'true'"
                        ></currency-input>
                      </v-col>

                      <v-col cols="12" md="5" class="text-right pl-0 pt-2 pr-5">
                        <v-btn
                          small
                          shaped
                          :disabled="!isFormCentrosCostosValid"
                          color="primary"
                          type="submit"
                        >
                          {{
                            internIdCentroCosto != null ? "Guardar" : "Agregar"
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-card-actions class="pb-0 pt-1 pr-0 ">
        <v-col cols="12" md="12" class="text-right p-0">
          <v-btn
            small
            outlined
            @click="toggleNuevoImputacionContable"
            color="error"
            class="mr-5"
            >Cerrar</v-btn
          >

          <v-btn
            small
            outlined
            :disabled="!banderaCambiosRealizados"
            @click="verificarValoresImputables"
            color="primary"
            >Confirmar</v-btn
          >
        </v-col>
      </v-card-actions>
    </v-container>
    <!-- MODAL BuscarCuentaContable -->
    <v-dialog
      v-model="buscarCuentaModal"
      v-if="buscarCuentaModal"
      @keydown.esc="toggleModalBusquedaCuenta"
      max-width="40rem"
      persistent
    >
      <BuscarCuentaContable
        @toggleModalBusquedaCuenta="toggleModalBusquedaCuenta"
        @findCuenta="findCuenta"
        :planCtaId="planCtaId"
        :mascara="mascaraImputContable"
      ></BuscarCuentaContable>
    </v-dialog>
    <ConfirmDialog
      :openConfirm.sync="confirmDialogCloseModal"
      :text="confirmDialogText"
      :title="'¿Confirmar cambios?'"
      @onConfirm="closeAndReloadTables"
    ></ConfirmDialog>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import helpers from "@/utils/helpers.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import BuscarCuentaContable from "@/components/modules/proveedores/BuscarCuentaContable.vue";

export default {
  name: "ImputacionCbleCentroCostosCargaFactura",
  components: {
    CurrencyInput,
    ConfirmDialog,
    BuscarCuentaContable
  },
  props: {
    EntFacId: { type: Number, require: false, default: null },
    empresaIdConta: { type: Number, require: false, default: null },
    ProvId: { type: Number, require: false, default: null },
    datosImputacionContable: { type: Object, require: false, default: null },
    totalImputable: { type: Number, require: false, default: 0 },
    centroCostosItemsProp: { type: Array, require: false, default: [] },
    imputacionContableItemsProp: { type: Array, require: false, default: [] }
  },
  directives: { mask },
  data() {
    return {
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      infoIcon: enums.icons.SNB_INFO,
      searchIcon: enums.icons.SEARCH,
      cuentaLoading: false,
      cuentaSelectedNom: null,
      internIdImputCble: null,
      internIdCentroCosto: null,
      cuentasItems: null,
      codigoCuenta: null,
      cuentaSelected: null,
      cuentaEsEgreso: false,
      cuentaSelectedCodigo: null,
      buscarCuentaModal: false,
      banderaCambiosRealizados: false,
      confirmDialogText: "",
      confirmDialogCloseModal: false,
      montoCuenta: null,
      tiposImputacionProveedor: [],
      tipoImputacionProveedorSelected: null,
      mascaraImputContable: "",
      planCtaId: null,
      centroCostoSelected: null,
      centroCostos: [],
      montoCentroCosto: null,
      tiposImputacionCentroCostoSelected: null,
      tiposImputacionCentroCosto: [],
      isFormImputCbleValid: false,
      isFormCentrosCostosValid: false,
      rules: rules,
      helpers: helpers,
      imputacionContableHeaders: [
        {
          text: "Cuenta",
          sortable: false,
          value: "cuenta",
          align: "start"
        },
        {
          text: "Descripción",
          sortable: false,
          value: "descripcion",
          align: "start"
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe",
          align: "end"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          width: "12%",
          align: "end"
        }
      ],
      imputacionContableItems: [],
      centroCostosHeaders: [
        {
          text: "Nombre",
          sortable: false,
          value: "nombre",
          align: "start"
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe",
          width: "30%",
          align: "end"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          width: "12%",
          align: "end"
        }
      ],
      centroCostosItems: [],
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 0,
          max: 999999999999999
        },
        precision: undefined,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true
      }
    };
  },
  computed: {
    totalImputacionContable() {
      if (
        this.imputacionContableItems &&
        this.imputacionContableItems.length > 0
      ) {
        return this.imputacionContableItems.reduce((total, item) => {
          return total + (item.importe ? item.importe : 0);
        }, 0);
      } else {
        return 0;
      }
    },
    totalCentrosDeCostos() {
      if (this.centroCostosItems && this.centroCostosItems.length > 0) {
        return this.centroCostosItems.reduce((total, item) => {
          return total + (item.importe ? item.importe : 0);
        }, 0);
      } else {
        return 0;
      }
    },
    totalGastoAImputar() {
      if (
        this.imputacionContableItems &&
        this.imputacionContableItems.length > 0
      ) {
        return this.imputacionContableItems.reduce((total, item) => {
          return total + (item.importe && item.esEgreso ? item.importe : 0);
        }, 0);
      } else {
        return 0;
      }
    }
  },
  mounted() {
    // Hacer copias de las props al montar el componente
    this.imputacionContableItems = [...this.imputacionContableItemsProp];
    this.centroCostosItems = [...this.centroCostosItemsProp];
  },
  async created() {
    if (this.empresaIdConta != null) {
      this.centroCostos = await this.getCentrosCostosByEmpresa(
        this.empresaIdConta
      );
      this.tiposImputacionCentroCosto = await this.getImputContablesByEmpresa(
        this.empresaIdConta
      );
      this.tiposImputacionProveedor = await this.getImputacionesContablesProveedores(
        {
          entFacId: this.EntFacId,
          provId: this.ProvId
        }
      );
    }
    if (this.datosImputacionContable != null) {
      this.planCtaId = this.datosImputacionContable.planCtaId;
      this.mascaraImputContable = this.datosImputacionContable.mascara.replace(
        /[0-9]/g,
        "#"
      );
    }
    this.$refs.codigoCuenta.focus();
  },

  methods: {
    ...mapActions({
      getCentrosCostosByEmpresa: "proveedores/getCentrosCostosByEmpresa",
      getCentroCostoById: "proveedores/getCentroCostoById",
      getImputacionContableDetalle: "proveedores/getImputacionContableDetalle",
      getImputContablesByEmpresa: "proveedores/getImputContablesByEmpresa",
      getCuentaContableBuscar: "proveedores/getCuentaContableBuscar",
      getCuentaContableBuscarxId: "proveedores/getCuentaContableBuscarxId",
      getImputacionesContablesProveedores:
        "proveedores/getImputacionesContablesProveedores",
      getImputacionesContablesDeProveedoresxId:
        "proveedores/getImputacionesContablesDeProveedoresxId",
      getCentrosCostosxImputacionesContablesProveedores:
        "proveedores/getCentrosCostosxImputacionesContablesProveedores",
      getImputacionesContablesDetalleCentroCostoProveedoresxId:
        "proveedores/getImputacionesContablesDetalleCentroCostoProveedoresxId",
      setAlert: "user/setAlert"
    }),

    setMontoCuentaPorDefecto() {
      if (this.internIdImputCble == null) {
        this.montoCuenta =
          this.totalImputable >= this.totalImputacionContable
            ? this.totalImputable - this.totalImputacionContable
            : 0;
      }
    },
    setMontoCentroCostoPorDefecto() {
      if (this.internIdCentroCosto == null) {
        this.montoCentroCosto =
          this.totalGastoAImputar >= this.totalCentrosDeCostos
            ? this.totalGastoAImputar - this.totalCentrosDeCostos
            : 0;
      }
    },

    async setImputacionContable() {
      this.centroCostosItems = [];
      if (this.tiposImputacionCentroCostoSelected != null) {
        const response = await this.getImputacionContableDetalle(
          this.tiposImputacionCentroCostoSelected
        );
        if (response.length > 0) {
          this.setCentrosDeCostosConfig(response);
        }
      }
    },
    setCentrosDeCostosConfig(detallesCentroCostos) {
      detallesCentroCostos.forEach((item, index) => {
        // Calcula el importe según el porcentaje
        const importe = (item.porcentaje / 100) * this.totalGastoAImputar;

        // Crea el objeto con los datos necesarios
        const nuevoItem = {
          ccId: item.ccId,
          nombre: item.ccNombre,
          internId: index + 1,
          importe: importe
        };

        // Agrega el nuevo objeto al array centroCostosItems
        this.centroCostosItems.push(nuevoItem);
      });
      this.banderaCambiosRealizados = true;
    },
    async setTipoImputacionProveedor() {
      if (this.tipoImputacionProveedorSelected != null) {
        let compTiId = this.tipoImputacionProveedorSelected.compTiId;
        const response = await this.getImputacionesContablesDeProveedoresxId(
          compTiId
        );
        if (response != null) {
          let cuenta = response.cuenta;
          this.cuentaSelected = cuenta.ctaId;
          this.codigoCuenta = cuenta.ctaCodigo;
          this.cuentaSelectedNom = cuenta.ctaNombre;
          this.cuentaEsEgreso = cuenta.esEgreso;
          this.cuentaSelectedCodigo = cuenta.ctaCodigo;
          this.setMontoCuentaPorDefecto();
          this.saveImputCble();
        }
        //setear cuentas contables de la response, falta configurar
      }
    },
    async setCentrosCostosxImputacionesContablesProveedores() {
      let compTiId = this.tipoImputacionProveedorSelected.compTiId;

      const response = await this.getCentrosCostosxImputacionesContablesProveedores(
        compTiId
      );
      if (response.length > 0) {
        this.setCentrosDeCostosConfig(response);
      }
      return response;
    },
    async buscarCuenta() {
      if (this.codigoCuenta != null) {
        this.cuentaLoading = true;
        const data = {
          planCtaId: this.planCtaId,
          nombre: this.codigoCuenta
        };
        const response = await this.getCuentaContableBuscar(data);
        if (response.length > 0) {
          let cuenta = response[0];
          this.cuentaSelected = cuenta.ctaId;
          this.codigoCuenta = cuenta.ctaCodigo;
          this.cuentaSelectedNom = cuenta.ctaNombre;
          this.cuentaEsEgreso = cuenta.esEgreso;
          this.cuentaSelectedCodigo = cuenta.ctaCodigo;

          this.setMontoCuentaPorDefecto();
        } else {
          this.cuentaSelected = null;
          this.cuentaSelectedNom = null;

          this.setAlert({
            type: "warning",
            message: "No se encontró la cuenta"
          });
        }
      } else {
        this.cuentaSelected = null;
        this.cuentaSelectedNom = null;
      }
      this.cuentaLoading = false;
    },
    findCuenta(cuenta) {
      this.cuentaEsEgreso = cuenta.esEgreso;
      this.cuentaSelected = cuenta.ctaId;
      this.codigoCuenta = cuenta.ctaCodigo;
      this.cuentaSelectedNom = cuenta.ctaNombre;
      this.cuentaSelectedCodigo = cuenta.ctaCodigo;

      this.setMontoCuentaPorDefecto();
    },
    toggleModalBusquedaCuenta() {
      this.buscarCuentaModal = !this.buscarCuentaModal;
    },
    toggleNuevoImputacionContable() {
      this.$emit("toggleNuevoImputacionContable");
    },
    verificarValoresImputables() {
      let costosDif = this.totalGastoAImputar != this.totalCentrosDeCostos;
      let imputDif = this.totalImputable != this.totalImputacionContable;
      let ambasDif =
        this.totalImputable != this.totalImputacionContable &&
        this.totalGastoAImputar != this.totalCentrosDeCostos;
      if (ambasDif) {
        this.confirmDialogText =
          "Se encontraron diferencias entre el total imputable y el total de las imputaciones contables y entre los gastos a imputar y el total de centros de costos. No se podrá grabar el comprobante.";
        this.confirmDialogCloseModal = true;
      } else if (costosDif) {
        this.confirmDialogText =
          "Se encontraron diferencias entre los gastos a imputar y el total de centros de costos. No se podrá grabar el comprobante.";
        this.confirmDialogCloseModal = true;
      } else if (imputDif) {
        this.confirmDialogText =
          "Se encontraron diferencias entre el total imputable y el total de las imputaciones contables. No se podrá grabar el comprobante.";
        this.confirmDialogCloseModal = true;
      } else {
        this.closeAndReloadTables();
      }
    },
    closeAndReloadTables() {
      this.$emit(
        "reloadTables",
        this.centroCostosItems,
        this.imputacionContableItems,
        this.totalCentrosDeCostos,
        this.totalImputacionContable
      );
      this.$emit("toggleNuevoImputacionContable");
    },
    saveImputCble() {
      const exists = this.imputacionContableItems.some(
        item =>
          item.ctaId === this.cuentaSelected &&
          (this.internIdImputCble == null ||
            this.internIdImputCble != item.internId)
      );

      if (exists) {
        this.setAlert({
          type: "warning",
          message: "La cuenta seleccionada ya existe en la lista."
        });
        return;
      }
      // Si 'this.internIdImputCble' tiene un valor, estamos en modo de edición
      if (this.internIdImputCble != null) {
        // Encuentra el índice del elemento a actualizar
        const index = this.imputacionContableItems.findIndex(
          item => item.internId === this.internIdImputCble
        );

        if (index !== -1) {
          // Actualiza el elemento en el array
          this.$set(this.imputacionContableItems, index, {
            internId: this.internIdImputCble,
            ctaId: this.cuentaSelected,
            cuenta: this.cuentaSelectedCodigo,
            esEgreso: this.cuentaEsEgreso,
            descripcion: this.cuentaSelectedNom,
            importe: this.montoCuenta
          });

          // Resetea la variable de internId para futuras adiciones
          this.internIdImputCble = null;

          this.setAlert({
            type: "success",
            message: "El registro ha sido actualizado correctamente."
          });
          this.banderaCambiosRealizados = true;
        } else {
          this.setAlert({
            type: "warning",
            message: "No se encontró el registro a actualizar."
          });
        }
      } else {
        // Modo de adición de nuevo registro

        const maxInternId =
          this.imputacionContableItems.length > 0
            ? Math.max(
                ...this.imputacionContableItems.map(item => item.internId)
              )
            : 0;

        const data = {
          internId: maxInternId + 1,
          ctaId: this.cuentaSelected,
          cuenta: this.cuentaSelectedCodigo,
          esEgreso: this.cuentaEsEgreso,
          descripcion: this.cuentaSelectedNom,
          importe: this.montoCuenta
        };
        this.banderaCambiosRealizados = true;

        this.imputacionContableItems.push(data);

        this.setAlert({
          type: "success",
          message: "El registro ha sido agregado correctamente."
        });
      }

      // Resetea el formulario
      this.montoCuenta = null;
      if (this.tipoImputacionProveedorSelected != null) {
        this.setCentrosCostosxImputacionesContablesProveedores();
      }
      this.$refs["imputacionContableForm"].reset();
      this.$refs.codigoCuenta.focus();
    },
    saveCentroCosto() {
      const exists = this.centroCostosItems.some(
        item =>
          item.ccId === this.centroCostoSelected.id &&
          (this.internIdCentroCosto == null ||
            this.internIdCentroCosto != item.internId)
      );

      if (exists) {
        this.setAlert({
          type: "warning",
          message: "El centro de costo seleccionado ya existe en la lista."
        });
        return;
      }
      // Si 'this.internIdCentroCosto' tiene un valor, estamos en modo de edición
      if (this.internIdCentroCosto != null) {
        // Encuentra el índice del elemento a actualizar
        const index = this.centroCostosItems.findIndex(
          item => item.internId === this.internIdCentroCosto
        );

        if (index !== -1) {
          // Actualiza el elemento en el array usando $set para asegurar la reactividad
          this.$set(this.centroCostosItems, index, {
            internId: this.internIdCentroCosto,
            ccId: this.centroCostoSelected.id,
            nombre: this.centroCostoSelected.value1,
            importe: this.montoCentroCosto
          });
          this.banderaCambiosRealizados = true;

          // Resetea la variable de internId para futuras adiciones
          this.internIdCentroCosto = null;

          this.setAlert({
            type: "success",
            message: "El centro de costo ha sido actualizado correctamente."
          });
        } else {
          this.setAlert({
            type: "warning",
            message: "No se encontró el centro de costo a actualizar."
          });
        }
      } else {
        // Modo de adición de nuevo registro

        const maxInternId =
          this.centroCostosItems.length > 0
            ? Math.max(...this.centroCostosItems.map(item => item.internId))
            : 0;

        const data = {
          internId: maxInternId + 1,
          ccId: this.centroCostoSelected.id,
          nombre: this.centroCostoSelected.value1,
          importe: this.montoCentroCosto
        };

        // Utilizamos $set para agregar el nuevo elemento al array para asegurar la reactividad
        this.$set(this.centroCostosItems, this.centroCostosItems.length, data);
        this.banderaCambiosRealizados = true;

        this.setAlert({
          type: "success",
          message: "El centro de costo ha sido agregado correctamente."
        });
      }

      // Resetea el formulario
      this.$refs["centrosCostosForm"].reset();
      this.$refs.centroCosto.focus();
    },

    editImputCble(itemToEdit) {
      this.cuentaEsEgreso = itemToEdit.esEgreso;
      this.cuentaSelected = itemToEdit.ctaId;
      this.codigoCuenta = itemToEdit.cuenta;
      this.cuentaSelectedCodigo = itemToEdit.cuenta;
      this.cuentaSelectedNom = itemToEdit.descripcion;
      this.internIdImputCble = itemToEdit.internId;

      this.montoCuenta = itemToEdit.importe;
    },
    setEditCentroCosto(itemToEdit) {
      this.centroCostoSelected = this.centroCostos.find(
        x => x.id == itemToEdit.ccId
      );

      this.montoCentroCosto = itemToEdit.importe;
      this.internIdCentroCosto = itemToEdit.internId;
    },
    deleteImputCble(item) {
      this.banderaCambiosRealizados = true;
      this.imputacionContableItems = this.imputacionContableItems.filter(
        x => x.internId !== item.internId
      );
    },
    deleteCentroCosto(item) {
      this.banderaCambiosRealizados = true;
      this.centroCostosItems = this.centroCostosItems.filter(
        x => x.internId !== item.internId
      );
    }
  }
};
</script>

<style></style>
